<template>
  <div class="header">
    <h1>Ride&nbsp;to&nbsp;play. <span>Play&nbsp;to&nbsp;ride.</span></h1>
  </div>
  <div class="image-row">
    <div class="col">
      <img src="@/assets/bogey-bikes-logo.png" alt="Bogey Bikes" />
    </div>
    <div class="col">
      <img src="@/assets/bike.png" alt="Bogey Bikes Bike" />
    </div>
  </div>
  <div class="waiver-row">
    <div class="col">Ready to play?</div>
    <div class="col">
      <router-link :to="{ name: 'waiverSafetyVideo' }">
        <Button label="Complete Rider Waiver" />
      </router-link>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Button.vue";

export default {
  components: {
    Button,
  },
};
</script>

<style lang="scss" scoped>
.header h1 {
  margin-top: 10vh;
  color: #fff;
  text-transform: uppercase;
  padding: 1em;
  font-size: 5vw;
  text-align: center;

  span {
    color: #000;
  }
}
.image-row {
  display: flex;
  max-width: 80vw;

  .col {
    width: 50%;

    img {
      width: 100%;
      height: auto;
    }
  }
}
.waiver-row {
  display: flex;
  max-width: 80vw;
  margin: 15px 0;
  justify-content: center;

  .col {
    display: flex;
    width: 50%;
    align-items: center;
  }

  .ready {
    margin-right: 8px;
  }
}

@media only screen and (max-width: 400px) {
  .image-row {
    flex-wrap: wrap;
    .col {
      width: 100% !important;
      margin-bottom: 15px;
    }
  }

  .waiver-row {
    flex-wrap: wrap;
    .col {
      justify-content: center;
      width: 100%;
      margin-bottom: 5px;

      button {
        margin: 0 5px;
      }
    }
  }
}
</style>
