<template>
  <button>{{ label }}</button>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
button {
  backface-visibility: hidden;
  position: relative;
  cursor: pointer;
  display: inline-block;
  white-space: nowrap;
  background: #222;
  border-radius: 0px;
  border: 3px solid #444;
  border-width: 3px 3px 3px 3px;
  padding: 5px 20px 5px 20px;
  color: #fff;
  font-family: Helvetica Neue;
  font-weight: 900;
  font-style: normal;
  border-radius: 5px;

  &:hover {
    border: 3px solid #222;
  }
}
</style>
