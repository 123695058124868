<template>
  <div class="logo-container">
    <router-link :to="{ name: 'home' }">
      <img class="logo" src="@/assets/bogey-bikes-logo.png" alt="Bogey Bikes" />
    </router-link>
  </div>
  <div class="header">
    <h1>Rider Waiver Complete</h1>
    <p>Your waiver has been completed. Have a great round of golf!</p>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;

  .logo {
    width: 80%;
    max-width: 300px;
    margin: 20px;
  }
}

.header {
  width: 100%;
  max-width: 900px;

  h1 {
    font-size: 1.8em;
    margin: 10px 18px;
  }

  p {
    margin: 0 18px 10px;
  }
}
</style>
