<template>
  <div class="logo-container">
    <router-link :to="{ name: 'home' }">
      <img class="logo" src="@/assets/bogey-bikes-logo.png" alt="Bogey Bikes" />
    </router-link>
  </div>
  <div class="header">
    <h1>Safety Video</h1>
    <p>
      Watch the safety video in its entirety before proceeding to the waiver
      form.
    </p>
  </div>
  <div class="video-wrap">
    <div class="video-container">
      <iframe
        src="https://www.youtube.com/embed/EwmKiAqfabo"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      />
    </div>
  </div>
  <div class="waiver-row">
    <div class="col">Finished the video?</div>
    <div class="col">
      <a
        href="https://forms.zohopublic.com/bogeybikes/form/RentalApplication/formperma/T1nAE2H3EWdSD0peU6h-ELVPY3m1zBGqovS1papD1q0"
      >
        <Button label="Complete Rider Waiver" />
      </a>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Button.vue";

export default {
  components: {
    Button,
  },
};
</script>

<style lang="scss" scoped>
.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;

  .logo {
    width: 80%;
    max-width: 300px;
    margin: 20px;
  }
}

.header {
  width: 100%;
  max-width: 900px;

  h1 {
    font-size: 1.8em;
    margin: 10px 18px;
  }

  p {
    margin: 0 18px 10px;
  }
}

/* And set the max-width of the parent element */
.video-wrap {
  width: 100%;
  max-width: 900px;
  /* Set your aspect ratio */
  .video-container {
    position: relative;
    overflow: hidden;
    height: 0;
    padding-bottom: 56.25%; /* creates a 16:9 aspect ratio */
  }
  .video-container iframe,
  .video-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-width: 100%;
  }
}

.waiver-row {
  display: flex;
  max-width: 80vw;
  margin: 15px 0;
  justify-content: center;

  .col {
    display: flex;
    width: 50%;
    align-items: center;
  }
}

@media only screen and (max-width: 400px) {
  .waiver-row {
    flex-wrap: wrap;
    .col {
      justify-content: center;
      width: 100%;
      margin-bottom: 5px;

      button {
        margin: 0 5px;
      }
    }
  }
}
</style>
