import { createRouter, createWebHashHistory } from "vue-router";
import Home from "@/views/Home.vue";
import SafetyVideo from "@/views/SafetyVideo.vue";
import WaiverComplete from "@/views/WaiverComplete.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/waiver/safety-video",
    name: "waiverSafetyVideo",
    component: SafetyVideo,
  },
  {
    path: "/waiver/complete",
    name: "waiverComplete",
    component: WaiverComplete,
  },
];

export default createRouter({
  history: createWebHashHistory(),
  routes,
});
