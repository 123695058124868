<template>
  <div class="content-container">
    <router-view />
  </div>
  <div class="footer">
    &copy;{{ new Date().getFullYear() }} Bogey Bikes.
    <a href="/BBPrivacy.pdf">Privacy Policy</a>
  </div>
</template>

<style lang="scss">
@import "@/assets/style/main.scss";

#app {
  background-image: linear-gradient(
    120deg,
    rgba(214, 212, 65, 1) 0%,
    rgba(56, 162, 70, 1) 100%
  );
  align-items: center;
  flex-direction: column;

  .content-container {
    width: 100vw;
    max-width: 1600px;
    min-height: calc(100vh - 30px);
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .footer {
    height: 30px;
    font-size: 0.8em;

    a {
      color: #000;
    }
  }
}
</style>
